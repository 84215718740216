import { Insta, Logo, Logo1, Phone, Ukras } from '../assets/images/images'

const NewHeader = () => {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <div className='newLogoDiv'>
                    <img src={Logo1} alt="Logo" />
                </div>
                <div className='newCafeName'>
                    CAFFE RESTORAN
                </div>
                <div className='newCafeNameColor'>
                    KEJ
                </div>
                <div className='newIconsWrap'>
                    <div className='newIconText'>
                        <div><img src={Insta} alt="Instagram" /></div>
                        <div style={{ paddingBottom: '0.4rem' }}><a style={{ color: 'white', textUnderlineOffset: '3px' }} href='https://www.instagram.com/kej_vlasotince/'>@kej_vlasotince</a></div>
                    </div>
                    <div className='newIconText'>
                        <div><img src={Phone} alt="Phone" /></div>
                        <div>
                            <div>Rezervacije-Dostava</div>
                            <div><a href="tel:0621215804" style={{ color: 'white', textUnderlineOffset: '3px' }}>062 1215804</a></div>
                        </div>
                    </div>
                </div>

                <div className='newLine1'></div>
                <div className='newLine2'></div>
                <div className='newLine3'></div>
                <div className='newLine4'></div>
                <div className='newLine5'></div>
                <div className='newLine6'></div>
            </div>
        </>
    )
}

export default NewHeader