import Header from '../sections/header'
import Footer from '../sections/footer'
import IndexButtons from '../sections/indexButtons'
import Action from '../sections/action'
// import NewHeader from '../sections/newHeader'
// import { Radnici } from '../assets/images/images'
import NewNewHeader from '../sections/newNewHeader'
import NewAction from '../sections/newAction'

const Main = () => {
    return (
        <div className='container'>
            <NewNewHeader />
            <IndexButtons />
            <NewAction />
            <Footer />
        </div>
    )
}

export default Main