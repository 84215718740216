import { Kafic, NoviKrug, NoviLogo, NoviLogoBezPozadinu } from "../assets/images/images"

const NewAction = () => {

    return (
        <div>
            <div className="akcija" style={{ textAlign: 'center', fontSize: 'xx-large', fontFamily: 'Fleur De Leah', color: '#D7A360' }}>
                <div style={{ paddingRight: '4rem' }}>Make the food</div>
                <div style={{ paddingLeft: '4rem' }}>great again</div>
            </div>
            <div style={{ position: 'relative' }} className="slikaNaMeni">
                <img src={Kafic} alt="Logo" style={{ margin: '0px auto', display: 'block', width: '70%', objectFit: 'cover' }} />
                <img src={NoviLogoBezPozadinu} alt="Logo" style={{ position: 'absolute', width: '5rem', top: '40%', left: '50%', transform: 'translateX(-50%)' }} />
            </div>
        </div>
    )
}

export default NewAction