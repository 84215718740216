import { useEffect, useState } from 'react'
import IndexButton from '../components/buttons/indexButton'
import axios from 'axios';
import { API_URL } from '../utils/api';
import { Link } from 'react-router-dom';

const IndexButtons = () => {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/category`).then((res) => {
            setCategories(res.data);
        }).catch((e) => {
            setError(e.message);
            setCategories([]);
        })
        setIsLoading(false);
    }, [])

    return (
        <div className="home-buttons">
            {/* {
                isLoading ? (
                    <>
                        <div className="home-button" style={{ opacity: 0.5 }}>Loading ...</div>
                        <div className="home-button" style={{ opacity: 0.5 }}>Loading ...</div>
                    </>
                ) : categories.map((category: ICategory) => {
                    return (
                        <Link key={category.id} style={{ textDecoration: 'none', color: 'white' }} to={`/meni/${category.id}`}><IndexButton text={category.name.toUpperCase()} onClick={() => { }} /></Link>
                    )
                })
            } */}
            {
                isLoading ? (
                    <>
                        <div className="home-button" style={{ opacity: 0.5 }}>Loading ...</div>
                        <div className="home-button" style={{ opacity: 0.5 }}>Loading ...</div>
                    </>
                ) :
                    (
                        <Link style={{ textDecoration: 'none', color: 'white' }} to={`https://upfoxstudio.com/meni/noooo.pdf`} target='_blank'><IndexButton text={"NAŠ MENI"} onClick={() => { }} /></Link>
                    )
            }
            {
                error && (<div>{error}</div>)
            }
            <Link style={{ textDecoration: 'none', color: 'white' }} to={`/perionica`}><div className="home-button blue-color">PERIONICA</div></Link>
        </div>
    )
}

export default IndexButtons