import { Insta, Logo, Logo1, NoviKrug, NoviLogo, Phone, ThymeLogo, Ukras } from '../assets/images/images'

const NewNewHeader = () => {
    return (
        <>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 2rem' }}>
                <div>
                    <img src={ThymeLogo} alt="Perionica Slika" style={{ width: '3rem' }} />
                </div>
                <div className='sinceText'>Since 2023.</div>
                <div>
                    <img src={ThymeLogo} alt="Perionica Slika" style={{ width: '3rem', transform: 'scaleX(-1)' }} />
                </div>
            </div>
        </>
    )
}

export default NewNewHeader